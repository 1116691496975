// Theme import structure is based on Bootstrap documentation
// https://getbootstrap.com/docs/5.2/customize/sass/#importing

// Barceloneta and Bootstrap import are using --load-path=node_modules

// Roboto is not included in this template and therefore we disable the setup here.
$enable-roboto-webfont: false !default;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Barceloneta and Bootstrap stylesheets
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.dark.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.properties";
@import "@plone/plonetheme-barceloneta-base/scss/variables.barceloneta";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.portlets.plone";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.font.plone";
@import "@plone/plonetheme-barceloneta-base/scss/root_variables";

// 6. Bootstrap base parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";

// 7. Bootstrap components
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// 8. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 9. Barceloneta base
@import "@plone/plonetheme-barceloneta-base/scss/alerts";
@import "@plone/plonetheme-barceloneta-base/scss/forms";
@import "@plone/plonetheme-barceloneta-base/scss/controlpanels";
@import "@plone/plonetheme-barceloneta-base/scss/login";
@import "@plone/plonetheme-barceloneta-base/scss/toolbar";
@import "@plone/plonetheme-barceloneta-base/scss/grid";
@import "@plone/plonetheme-barceloneta-base/scss/content_base";
@import "@plone/plonetheme-barceloneta-base/scss/content_tables";

// Barceloneta full
@import "@plone/plonetheme-barceloneta-base/scss/cards";
@import "@plone/plonetheme-barceloneta-base/scss/scaffolding";
@import "@plone/plonetheme-barceloneta-base/scss/icons";
@import "@plone/plonetheme-barceloneta-base/scss/header";
@import "@plone/plonetheme-barceloneta-base/scss/sitenav";
@import "@plone/plonetheme-barceloneta-base/scss/breadcrumbs";
@import "@plone/plonetheme-barceloneta-base/scss/content";
@import "@plone/plonetheme-barceloneta-base/scss/comments";
@import "@plone/plonetheme-barceloneta-base/scss/portlets";
@import "@plone/plonetheme-barceloneta-base/scss/footer";

@import "@plone/plonetheme-barceloneta-base/scss/print";

// @import "@plone/plonetheme-barceloneta-base/scss/roboto-webfont";


// 9. Add additional custom code here
@import "_kflvariables";

body {
    font-family: $kfl-font;
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
    color: $kfl-primary-color;
    background-color: $kfl-primary-background-color;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $kfl-font;
    font-weight: 600;
    color: $kfl-primary-color;
    line-height: 41px;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

input {
    font-family: $kfl-font;
    font-size: 16px;
}

a:link, a:visited {
    color: $kfl-link-color;
    text-decoration: none;
}

div.width-full {
    width: 100%;
}

div.position-0 {
    margin-left: -100%;
}

/*----------------HEADER-----------------*/

#content-header {
    height: 120px;
    background-color: $kfl-header-bgcolor;

    #kopf {
        width: 100%;
        height: 37px;
        background-color: $kfl-header-bgcolor;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        img {
            margin-top: -1px;
        }
    }

    #portal-slogan {
        color: $kfl-link-color;
        font-family: $kfl-font;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }

    #untertitel {
        color: $kfl-link-color;
        font-size: 12px;
        text-align: center;
    }
}

/*----------------HEADER-----------------*/

#visual-portal-wrapper {
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}

/*----------------SEARCHBAR-----------------*/

#mainnavigation-wrapper {
    // height: 20px;

    .navbar {
        // background-color: $kfl-header-bgcolor;

        button {
            color: #000000;
        }

        margin-top: 10px;
        right: 20px;
    }

    .plone-navbar {
        background-color: $kfl-primary-background-color;
        box-shadow: none;
        margin-bottom: 0px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ebebeb;
    }

    .plone-navbar-nav {
        margin: 0 -35px 0;
    }

    button.plone-navbar-toggle {
        background-color: $kfl-secondary-color;

        &:hover {
            border-color: $kfl-light-secondary-color;
            background-color: $kfl-light-secondary-color;
        }
    }

    input[type="text"]:focus, textarea:focus {
        background-color: #ffffff;
        border: 1px solid $kfl-border-color;
    }

    #portal-searchbox {

        .searchField {
            -moz-appearance: searchfield;
            width: 100%;
            margin: 10px 0px 0px 16px;
            border: 0px solid $kfl-primary-background-color;
            background-color: $kfl-primary-background-color;
            box-shadow: none;
        }

        .searchButton {
            background-color: $kfl-primary-background-color;
            margin: 10px 0 5px 20px;
            border: 0px solid $kfl-border-color;
            padding: 5px 5px 5px 4px;
            color: $kfl-primary-color;
        }

        .livesearch-results {
            padding: 10px;
            right: auto;
        }

        li.search-result {
            background-color: $kfl-primary-background-color;
        }

        .pat-livesearch{
            min-width: 230px;
            position:relative;
            color: #000000;

            .livesearch-results{
                display:none;
                position: absolute;
                background-color: $kfl-primary-color;
                z-index: 100;
                opacity: inherit;
                visibility: visible;

                border-right: 1px solid #64bee8;
                border-bottom: 1px solid #64bee8;
                border-left: 1px solid #64bee8;
                outline: 0;
                -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
                -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);

                margin: 0;
                padding: 0;
                list-style: none;

                a {
                    color: $kfl-secondary-color;
                    background-color: $kfl-primary-background-color;

                    &:hover {
                        color: $kfl-secondary-color;
                    }
                }

                li{
                    white-space: normal;
                    margin: 0;
                    padding: 5px;
                    cursor: pointer;

                    &.load-more{
                        .next{
                            float: right;
                        }
                        .prev{
                            float: left;
                        }
                        .page{
                            text-align: center;
                        }
                    }
                }
                li:hover,li.selected{
                    background-color: $kfl-primary-background-color;
                }
            }
        }
    }

    #kfl-user-box {
        width: 15%;
        #kfl-anontools {
            ul {
                padding: 0;
            }

            a, a:hover {
                background-color: $kfl-primary-background-color;
                color: $kfl-light-secondary-color;
                font-family: $kfl-font;
                font-weight: 600;
                font-size: 16px;
                line-height: 25px;
                padding: 8% 0 0 0;
            }
        }

        .kfl-user-icon {
            // margin: 2% 0px 0px 75%;
            // padding-left: 65px;
            color: $kfl-primary-color;
        }
    }
}



/*----------------SEARCHBAR-----------------*/

/*----------------BREADCRUMS-----------------*/

#portal-breadcrumbs {
    font-size: 12px;
    font-weight: 500;
    color: $kfl-primary-color;
    font-family: $kfl-font;
    background-color: #fff;
    padding-top: 20px;

    a:link, a:visited {
       color: $kfl-primary-color;
   }

   #breadcrumbs-1, #breadcrumbs-2, #breadcrumbs-3, #breadcrumbs-4 {
       color: $kfl-secondary-color
   }
}

/*----------------BREADCRUMS-----------------*/

/*----------------CONTENT-----------------*/

#content {
    a:link,
    dl.portlet a:link,
    div.mainfid {
        color: $kfl-link-color;
        font-weight: 600;
        font-family: $kfl-font;
        border-bottom: 0em solid #fff;
        font-size: 16px;
    }

    a:visited, dl.portlet a:visited {
        color: $kfl-link-color;
        border-bottom: 0.1em solid #fff;
    }

    a:hover,
    dl.portlet a:hover,
    a.navhighlight {
        color: $kfl-secondary-color !important; /* This one gets an !important because it should always activate in the content area, even when state coloring is active */
    }

    a.link-overlay {
        border-bottom: none !important;
    }

    h1.documentFirstHeading {
        margin-top: -15px;
    	margin-bottom: 1em;
    	color: $kfl-primary-color;
        font-family: $kfl-font;
        font-size: 24px;
        font-weight: 600;
        line-height: 41px;
        letter-spacing: 0.02em;
    }

    p {
        margin-bottom: 1em;
        color: $kfl-primary-color;
        font-family: $kfl-font;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}

/*----------------CONTENT-----------------*/


/*----------------FOOTER-----------------*/

#portal-footer-wrapper {
  background-color: #ebeae8;
  text-align: center;

  img {
      vertical-align: text-top;
      padding-left: 3.6em;
      padding-right: 4em;
  }

  a {
      color: $kfl-primary-color;
  }

  #portal-footer {
      .site-actions {
          text-align: center;
          color: $kfl-primary-color;
          font-weight: 600;
          font-family: $kfl-font;
          font-size: 16px;

          li {
              padding: 10px 10px 0 0;

              a:hover {
                  color: $kfl-secondary-color;
              }

              &:nth-child(1), &:nth-child(2) {
                  &:after {
                      content:"\00A0\00A0\00A0|";
                  }
              }
          }

          a:link, a:visited {
             color: $kfl-primary-color;
         }
      }
  }
}

/*----------------FOOTER-----------------*/

/*----------------NAVIGATION-----------------*/

#portal-column-one {
    #portal-searchbox {

        .searchField {
            -moz-appearance: searchfield;
            width: 40%;
            margin: 20px 0px 0px 0px;
            border: 0px solid $kfl-primary-background-color;
            background-color: $kfl-primary-background-color;
            box-shadow: none;
        }

        .searchButton {
            background-color: $kfl-primary-background-color;
            margin: 10px 0 5px 0px;
            border: 0px solid $kfl-border-color;
            padding: 5px 5px 5px 4px;
            color: $kfl-primary-color;
        }

        .livesearch-results {
            padding: 10px;
            right: auto;
        }

        li.search-result {
            background-color: $kfl-primary-background-color;
        }

        .pat-livesearch{
            min-width: 230px;
            position:relative;
            color: #000000;

            .livesearch-results{
                display:none;
                position: absolute;
                background-color: $kfl-primary-color;
                z-index: 100;
                opacity: inherit;
                visibility: visible;

                border-right: 1px solid #64bee8;
                border-bottom: 1px solid #64bee8;
                border-left: 1px solid #64bee8;
                outline: 0;
                -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
                -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);

                margin: 0;
                padding: 0;
                list-style: none;

                a {
                    color: $kfl-secondary-color;
                    background-color: $kfl-primary-background-color;

                    &:hover {
                        color: $kfl-secondary-color;
                    }
                }

                li{
                    white-space: normal;
                    margin: 0;
                    padding: 5px;
                    cursor: pointer;

                    &.load-more{
                        .next{
                            float: right;
                        }
                        .prev{
                            float: left;
                        }
                        .page{
                            text-align: center;
                        }
                    }
                }
                li:hover,li.selected{
                    background-color: $kfl-primary-background-color;
                }
            }
        }
    }
}
.portletWrapper {
    #portletKFLNavigation {
        display: none;

        a {
            font-family: $kfl-font;
            font-weight: 600;
            font-size: 16px;
            color: $kfl-primary-color;
            border-bottom: 0em solid #fff;

            &:hover {
                color: $kfl-secondary-color;
            }
        }

        a.navhighlight {
            color: $kfl-secondary-color;
        }

        ul {

            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 1em;
                color: $kfl-primary-color;
                font-family: $kfl-font;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                padding: 5px 17px 18px 5px;
                border-bottom: 1px solid $kfl-border-color;
            }

            &:nth-child(1) {
                border-top: 1px solid $kfl-border-color;
                padding-top: 10px;
            }
        }
    }
}

#kfl-dfg-logo {
    padding: 1em;
}

.new-product, .kfl-title-addi {
    color: #f06b4e;
    font-size: 1em;
}

/*----------------NAVIGATION-----------------*/

/*----------------FORMULAR-----------------*/

#fieldset-address {
    #form-widgets-street {
        width: 20em;
        min-height: 4em;
    }

    #form-widgets-zip {
        width: 7em;
    }

    #form-widgets-city {
        width: 15em;
    }

    #form-widgets-url {
        width: 30em;
    }
}

#fieldset-contact {
    #form-widgets-IEduInstitution-email {
        width: 25em;
    }

    #form-widgets-telephone {
        width: 20em;
    }
}

// Starseite
.Spalten {
	max-width: 100%;
	text-align: center;
	padding: 0 1em;
}

.box {
	width: 80%;
	background: #dee9f2;
	box-shadow: 0 2px 3px rgba(0,0,0,0.2);
	height: 5em;
	display: flex;
	align-items: center;
	justify-content: center;
	float: left;
	color: #616669;
	font-family: Raleway;
	font-size: 14px;
	font-weight: 501;
	line-height: 22px;
	box-sizing: border-box;
	background-color: rgb(253, 253, 253);
	border-color: rgb(80, 139, 191);
	border-radius: 8px;
	border-style: solid;
	border-width: 1px;
	margin: 37px;
}

// Starseite


/*----------------FORMULAR-----------------*/

// Responsiv
// Breakpoints, siehe https://getbootstrap.com/docs/5.3/layout/breakpoints/

@include media-breakpoint-up(md) {

    .box {
        width: 30%;
    }

    #content-header {
        height: 180px;

        #portal-slogan {
            font-size: 45px;
            margin-top: 10px;
        }

        #untertitel {
            font-size: 16px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .box {
        width: 25%;
    }

    // #mainnavigation-wrapper {
    //     height: 0px;
    // }
    
    #portal-globalnav {
        visibility: hidden;
    }

    .portletWrapper {
        #portletKFLNavigation {
            display: block;
        }
    }
}

@include media-breakpoint-up(xl) {

    .box {
        width: 20%;
    }
}

@include media-breakpoint-up(xxl) {

    .box {
        width: 17%;
    }
}

// @media screen and (max-width: $plone-screen-md-max) {
//     .kfl-user-icon {
//         margin: 3% 0px 0px 70%;
//     }
// }

// @media screen and (max-width: $plone-screen-sm-max) {
//     .kfl-user-icon {
//         margin: 5% 0px 0px 60%;
//     }
// }

// @media screen and (max-width: $plone-screen-xs-max) {
//     .kfl-user-icon {
//         display: none;
//     }
// }


// @media screen and (max-width: $plone-screen-xs-max) {
//     #portal-slogan {
//         display: none;
//     }
// }

// @media screen and (max-width: $plone-screen-xs-max) {
//     #content-header {
//         height: 80px;
//     }
// }
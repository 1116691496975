// Häufig genutzte Werte

$kfl-font: Raleway;
$kfl-primary-color: #616669;
$kfl-secondary-color: #ea360f;
$kfl-primary-background-color: #fdfdfd;
$kfl-secondary-background-color: #ebeae8;
$kfl-link-color: #1c335f;
$kfl-border-color: #ebebeb;
$kfl-light-secondary-color: #f06b4e;
$kfl-header-bgcolor: #fff;